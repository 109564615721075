import { Prop, Vue } from '@/lib/decorator';
import { ref } from 'vue';

import { countSmsMessages } from '@/lib/Utils';
import { EXCEED_LENGTH_MESSAGE } from '@/lib/util/consts';
import formValidate from '@/lib/formFactory/validate';
import { EventBus } from '@/lib/event-bus';
import NotificationsModule from '@/store/settings/notifications';
import { Marker, TextFieldMaxLengthEvent } from '@/interfaces/notifications.interface';

export interface MaxLengthErrorPayload {
  possiblyExceeds: Record<string, boolean>;
  exactlyExceeds: Record<string, boolean>;
}

export default abstract class NotificationsRemind extends Vue {
  @Prop() data!: never;

  abstract readonly modelName: string;

  form = ref(null);
  validate = formValidate(this.form);
  model: Record<string, Record<string, string>> = this.data;
  errorsMaxLength: MaxLengthErrorPayload = {
    exactlyExceeds: {},
    possiblyExceeds: {},
  };

  warningMessage = EXCEED_LENGTH_MESSAGE;
  countSmsMessages = countSmsMessages;

  get markers(): Marker[] {
    return NotificationsModule.markers;
  }

  get prepareModel(): Record<string, string | number> {
    const data: Record<string, string | number> = {};

    for (const field in this.model) {
      if (typeof this.model[field] !== 'object') {
        data[field] = +this.model[field];
      } else {
        for (const key in this.model[field]) {
          data[`${field}[${key}]`] = this.model[field][key];
        }
      }
    }

    return data;
  }

  openMarkdownModal(): void {
    EventBus.$emit('showModal', { name: 'markdown', flag: true });
  }

  validateMaxLength(value: TextFieldMaxLengthEvent): void {
    this.errorsMaxLength.exactlyExceeds[value.name] = value.rawLength > value.maxLength;
    this.errorsMaxLength.possiblyExceeds[value.name] = value.estimatedMaxLength > value.maxLength;

    this.$emit('errorsMaxLength', this.errorsMaxLength);
  }

  checkShowWarningIcon(rawLength: number, estimatedMaxLength: number, maxLength: number): boolean {
    return rawLength <= maxLength && estimatedMaxLength > maxLength;
  }

  created(): void {
    this.emitModel();
  }

  emitModel(): void {
    this.$emit(this.modelName, this.prepareModel);
  }
}
