
import { Component } from '@/lib/decorator';

import FormSimpleSwitch from '@/components/form/Switch/SimpleSwitch.vue';
import IconFont from '@/components/icons/IconFont.vue';
import { GuiTextField, GuiTextInput } from '@library/gigant_ui';

import AbstractNotification from './AbstractNotification';

@Component({
  emits: ['errorsMaxLength', 'firstShiftModel'],
  components: {
    FormSimpleSwitch,
    IconFont,
    GuiTextField,
    GuiTextInput,
  },
})
export default class NotificationsCancelShift extends AbstractNotification {
  readonly modelName = 'firstShiftModel';
}
