
import { Component } from '@/lib/decorator';

import FormInput from '@/components/form/Input/index.vue';
import FormSimpleSwitch from '@/components/form/Switch/SimpleSwitch.vue';
import IconFont from '@/components/icons/IconFont.vue';
import { GuiTextField, GuiTextInput } from '@library/gigant_ui';

import AbstractNotification from './AbstractNotification';

@Component({
  emits: ['errorsMaxLength', 'infoModel'],
  components: {
    FormSimpleSwitch,
    FormInput,
    GuiTextField,
    GuiTextInput,
    IconFont,
  },
})
export default class NotificationsInfo extends AbstractNotification {
  readonly modelName = 'infoModel';

  get smsCount(): number {
    return this.countSmsMessages(this.model.sms_info_template['all_day']);
  }
}
