
import { Component } from '@/lib/decorator';

import FormSimpleSwitch from '@/components/form/Switch/SimpleSwitch.vue';
import { GuiTextField } from '@library/gigant_ui';
import IconFont from '@/components/icons/IconFont.vue';
import { Marker } from '@/interfaces/notifications.interface';

import AbstractNotification from './AbstractNotification';

/*
 * TODO: GIGD-630
 */
export const newMarketMarkers = [
  {
    description: 'Имя исполнителя',
    marker: '{name}',
    max: 64,
    min: 1,
  },
  {
    description: 'Название объекта',
    marker: '{market_name}',
    max: 162,
    min: 4,
  },
];

@Component({
  emits: ['errorsMaxLength', 'newMarketModel'],
  components: {
    GuiTextField,
    FormSimpleSwitch,
    IconFont,
  },
})
export default class NotificationsNewMarket extends AbstractNotification {
  readonly modelName = 'newMarketModel';

  get markers(): Marker[] {
    return newMarketMarkers;
  }
}
