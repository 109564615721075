
import { Component } from '@/lib/decorator';

import FormSimpleSwitch from '@/components/form/Switch/SimpleSwitch.vue';
import IconFont from '@/components/icons/IconFont.vue';
import { GuiTextField, GuiTextInput } from '@library/gigant_ui';

import AbstractNotification from './AbstractNotification';

@Component({
  emits: ['errorsMaxLength', 'firstShiftModel'],
  components: {
    FormSimpleSwitch,
    GuiTextField,
    GuiTextInput,
    IconFont,
  },
})
export default class NotificationsFirstShiftDone extends AbstractNotification {
  readonly modelName = 'firstShiftModel';

  get smsCount(): number {
    return this.countSmsMessages(this.model.sms_first_shift_done_template['all_day']);
  }
}
