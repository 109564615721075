
import { Component } from '@/lib/decorator';

import FormSimpleSwitch from '@/components/form/Switch/SimpleSwitch.vue';
import { GuiTextField } from '@library/gigant_ui';
import IconFont from '@/components/icons/IconFont.vue';
import { Marker } from '@/interfaces/notifications.interface';

import AbstractNotification from './AbstractNotification';
import NotificationsModule from '@/store/settings/notifications';
import { NotificationMarker } from '@/lib/util/consts';

/*
 * TODO: GIGD-630
 */
export const getAutoAssignedMarkers = (): Marker[] => [
  ...NotificationsModule.markers.filter(({ marker }) =>
    ([
      NotificationMarker.Name,
      NotificationMarker.DaysInterval,
      NotificationMarker.ShiftDay,
      NotificationMarker.ShiftMonthWhen,
      NotificationMarker.ShiftStart,
      NotificationMarker.Address,
    ] as string[]).includes(marker)
  ),
  ...[
    {
      marker: '{auto_assigned_shift_timeout}',
      description: 'Макс. часов для подтверждения смены',
      min: 1,
      max: 3,
    },
    {
      marker: '{auto_assigned_shift_deadline}',
      description: 'Время истечения подтверждения',
      min: 5,
      max: 5,
    },
  ],
];

@Component({
  emits: ['errorsMaxLength', 'autoAssignedModel'],
  components: {
    GuiTextField,
    FormSimpleSwitch,
    IconFont,
  },
})
export default class NotificationsAutoAssigned extends AbstractNotification {
  readonly modelName = 'autoAssignedModel';

  get markers(): Marker[] {
    return getAutoAssignedMarkers();
  }
}
