
import { Prop, Component } from '@/lib/decorator';

import FormSimpleSwitch from '@/components/form/Switch/SimpleSwitch.vue';
import { GuiTextField, GuiTextInput } from '@library/gigant_ui';
import IconFont from '@/components/icons/IconFont.vue';

import AbstractNotification from './AbstractNotification';

@Component({
  emits: ['errorsMaxLength', 'remindModel'],
  components: {
    GuiTextField,
    GuiTextInput,
    FormSimpleSwitch,
    IconFont,
  },
})
export default class NotificationsRemind extends AbstractNotification {
  @Prop() timesOfDay!: string;

  readonly modelName = 'remindModel';

  get smsCount(): number {
    return this.countSmsMessages(this.model.sms_confirm_template[this.timesOfDay]);
  }
}
