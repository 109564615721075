import { NotificationTimesOfDay } from '@/interfaces/notifications.interface';

export const systemItems = [
  {
    id: 'remind',
    value: 'Подтверждение выхода',
    name: 'Подтверждение выхода',
  },
  {
    id: 'info',
    value: 'Напоминание и инфо',
    name: 'Напоминание и инфо',
  },
  {
    id: 'first_shift',
    value: 'Первый заказ (до)',
    name: 'Напоминание и инфо',
  },
  {
    id: 'first_shift_done',
    value: 'Первый заказ (после)',
    name: 'Первый заказ (после)',
  },
  {
    id: 'cancel_shift',
    value: 'Отмена заказ',
    name: 'Отмена заказ',
  },
  {
    id: 'reactivation',
    value: 'Реактивация через 30 дней',
    name: 'Реактивация через 30 дней',
  },
  {
    id: 'checkin',
    value: 'CheckIn/CheckOut',
    name: 'CheckIn/CheckOut',
  },
  {
    id: 'new_market',
    value: 'Новый объект',
    name: 'Новый объект',
  },
  {
    id: 'auto_assigned',
    value: 'Автопланирование',
    name: 'Автопланирование',
  },
];

export const timesOfDayItems: { id: NotificationTimesOfDay; value: string }[] = [
  {
    id: 'dawn',
    value: 'Ранее утро 05:00 - 07:59',
  },
  {
    id: 'morning',
    value: 'Утро 08:00 - 10:59',
  },
  {
    id: 'midday',
    value: 'День (1) 11:00 - 14:59',
  },
  {
    id: 'afternoon',
    value: 'День (2) 15:00 - 16:59',
  },
  {
    id: 'evening',
    value: 'Вечер 17:00 - 18:59',
  },
  {
    id: 'dusk',
    value: 'Поздний вечер 19:00 - 23:59',
  },
  {
    id: 'night',
    value: 'Ночь 00:00 - 04:59',
  },
];
