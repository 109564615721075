
import { ref } from 'vue';

import { Component, Vue } from '@/lib/decorator';

import SettingsModule from '@/store/settings';
import NotificationsModule from '@/store/settings/notifications';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { systemItems, timesOfDayItems } from './selectItems';
import { Marker, NotificationParameters, NotificationTimesOfDay } from '@/interfaces/notifications.interface';

import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import FormSelect from '@/components/form/Select/index.vue';
import FormButton from '@/components/form/Button/index.vue';
import formValidate from '@/lib/formFactory/validate';
import Markers from '@/components/Markers.vue';

import Remind from './Remind.vue';
import Info from './Info.vue';
import FirstShift from './FirstShift.vue';
import FirstShiftDone from './FirstShiftDone.vue';
import CancelShift from './CancelShift.vue';
import Reactivation from './Reactivation.vue';
import CheckInCheckOut from './CheckInCheckOut.vue';
import NewMarket, { newMarketMarkers } from './NewMarket.vue';
import AutoAssigned, { getAutoAssignedMarkers } from './AutoAssigned.vue';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import { MaxLengthErrorPayload } from './AbstractNotification';
import { GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    CancelShift,
    FirstShiftDone,
    TabsNav,
    FormSelect,
    FormButton,
    Markers,
    Remind,
    Info,
    FirstShift,
    Reactivation,
    CheckInCheckOut,
    NewMarket,
    AutoAssigned,
  },
})
export default class NotificationsMain extends Vue {
  isLoader = false;
  isLoaderSave = false;
  errorsMaxLength = false;

  remind: NotificationParameters = {};
  remindModel: NotificationParameters = {};

  info: NotificationParameters = {};
  infoModel: NotificationParameters = {};

  firstShift: NotificationParameters = {};
  firstShiftModel: NotificationParameters = {};

  firstShiftDone: NotificationParameters = {};
  firstShiftDoneModel: NotificationParameters = {};

  cancelShift: NotificationParameters = {};
  cancelShiftModel: NotificationParameters = {};

  reactivation: NotificationParameters = {};
  reactivationModel: NotificationParameters = {};

  checkin: NotificationParameters = {};
  checkinModel: NotificationParameters = {};

  newMarket: NotificationParameters = {};
  newMarketModel: NotificationParameters = {};

  autoAssigned: NotificationParameters = {};
  autoAssignedModel: NotificationParameters = {};

  form = ref(null);
  validate = formValidate(this.form);

  systemItems = systemItems;
  system = this.systemItems[0];

  timesOfDayItems = timesOfDayItems;
  timesOfDay = this.timesOfDayItems[0];

  get tabsNav(): TabsNavInterface {
    return SettingsModule.tabsNav;
  }

  get currentTab(): string {
    return SettingsModule.allTabs.notifications?.id;
  }

  get markers(): Marker[] {
    switch (this.system.id) {
      case 'new_market':
        return newMarketMarkers;
      case 'auto_assigned':
        return getAutoAssignedMarkers();
    }

    return NotificationsModule.markers;
  }

  setRemindModel(value: NotificationParameters): void {
    this.remindModel = value;
  }

  setInfoModel(value: NotificationParameters): void {
    this.infoModel = value;
  }

  setFirstShiftModel(value: NotificationParameters): void {
    this.firstShiftModel = value;
  }

  setFirstShiftDoneModel(value: NotificationParameters): void {
    this.firstShiftDoneModel = value;
  }

  setCancelShiftModel(value: NotificationParameters): void {
    this.cancelShiftModel = value;
  }

  setReactivationModel(value: NotificationParameters): void {
    this.reactivationModel = value;
  }

  setCheckInCheckOutModel(value: NotificationParameters): void {
    this.checkinModel = value;
  }

  setNewMarketModel(value: NotificationParameters): void {
    this.newMarketModel = value;
  }

  setAutoAssignedModel(value: NotificationParameters): void {
    this.autoAssignedModel = value;
  }

  setErrorsMaxLength(value: MaxLengthErrorPayload): void {
    const isError = Object.values(value.exactlyExceeds).some(Boolean);
    const isWarning = Object.values(value.possiblyExceeds).some(Boolean);

    this.errorsMaxLength = isError;
    NotificationsModule.SET_WARNING(isWarning);
  }

  async getRemindList(): Promise<void> {
    this.remind = (await NotificationsModule.getRemindList()) ?? {};
  }

  async getInfoList(): Promise<void> {
    this.info = (await NotificationsModule.getInfoList()) ?? {};
  }

  async getFirstShiftList(): Promise<void> {
    this.firstShift = (await NotificationsModule.getFirstShiftList()) ?? {};
  }

  async getFirstShiftDoneList(): Promise<void> {
    this.firstShiftDone = (await NotificationsModule.getFirstShiftDoneList()) ?? {};
  }

  async getCancelShiftList(): Promise<void> {
    this.cancelShift = (await NotificationsModule.getCancelShiftList()) ?? {};
  }

  async getReactivationList(): Promise<void> {
    this.reactivation = (await NotificationsModule.getReactivationList()) ?? {};
  }

  async getCheckInCheckOutList(): Promise<void> {
    this.checkin = (await NotificationsModule.getCheckInCheckOutList()) ?? {};
  }

  async getNewMarketList(): Promise<void> {
    this.newMarket = (await NotificationsModule.getNewMarketList()) ?? {};
  }

  async getAutoAssignedList(): Promise<void> {
    this.autoAssigned = (await NotificationsModule.getAutoAssignedList()) ?? {};
  }

  async save(): Promise<void> {
    if (this.checkExceedMaxLength()) {
      return;
    }

    this.isLoaderSave = true;

    switch (this.system.id) {
      case 'remind': {
        await NotificationsModule.save({ model: 'remindModel', data: this.remindModel });
        break;
      }
      case 'info': {
        await NotificationsModule.save({ model: 'infoModel', data: this.infoModel });
        break;
      }
      case 'first_shift': {
        await NotificationsModule.save({ model: 'firstShiftModel', data: this.firstShiftModel });
        break;
      }
      case 'first_shift_done': {
        await NotificationsModule.save({ model: 'firstShiftDoneModel', data: this.firstShiftDoneModel });
        break;
      }
      case 'cancel_shift': {
        await NotificationsModule.save({ model: 'cancelShiftModel', data: this.cancelShiftModel });
        break;
      }
      case 'reactivation': {
        await NotificationsModule.save({ model: 'reactivationModel', data: this.reactivationModel });
        break;
      }
      case 'checkin': {
        await NotificationsModule.save({ model: 'checkInCheckOutModel', data: this.checkinModel });
        break;
      }
      case 'new_market': {
        await NotificationsModule.save({ model: 'newMarketModel', data: this.newMarketModel });
        break;
      }
      case 'auto_assigned': {
        await NotificationsModule.save({ model: 'autoAssignedModel', data: this.autoAssignedModel });
        break;
      }
    }

    this.isLoaderSave = false;
  }

  async reset(): Promise<void> {
    this.isLoader = false;

    if (this.system.id === 'remind') {
      await this.getRemindList();
    } else {
      await this.getInfoList();
    }

    this.isLoader = true;
  }

  async updateSystem(selected: { id: string; value: string }): Promise<void> {
    if (selected) {
      localStorage.setItem('notifySystem', selected.id ?? '');
    }

    if (this.checkExceedMaxLength()) {
      return;
    }

    //
    // обновляем данные в выбранной вкладке
    //
    switch (selected.id) {
      case 'remind': {
        await this.getRemindList();
        break;
      }
      case 'info': {
        await this.getInfoList();
        break;
      }
      case 'first_shift': {
        await this.getFirstShiftList();
        break;
      }
      case 'first_shift_done': {
        await this.getFirstShiftDoneList();
        break;
      }
      case 'cancel_shift': {
        await this.getCancelShiftList();
        break;
      }
      case 'reactivation': {
        await this.getReactivationList();
        break;
      }
      case 'checkin': {
        await this.getCheckInCheckOutList();
        break;
      }
      case 'new_market': {
        await this.getNewMarketList();
        break;
      }
      case 'auto_assigned': {
        await this.getAutoAssignedList();
        break;
      }
    }

    this.system = selected as { id: string; value: string; name: string };
  }

  updateTimesOfDay(selected: { id: string }): void {
    if (selected) {
      localStorage.setItem('notifyTimesOfDay', selected.id ?? '');
    }

    if (this.checkExceedMaxLength()) {
      return;
    }

    this.timesOfDay = selected as { id: NotificationTimesOfDay; value: string };
  }

  initFilterSystem(): void {
    if (!localStorage.getItem('notifySystem')) {
      return;
    }

    this.system = this.systemItems.find((item: { id: string; value: string }) => {
      return item.id === localStorage.getItem('notifySystem');
    }) as { id: string; value: string; name: string };

    this.updateSystem(this.system);
  }

  initFilterTimesOfDay(): void {
    if (!localStorage.getItem('notifyTimesOfDay')) {
      return;
    }

    this.timesOfDay = this.timesOfDayItems.find((item: { id: NotificationTimesOfDay; value: string }) => {
      return item.id === localStorage.getItem('notifyTimesOfDay');
    }) as { id: NotificationTimesOfDay; value: string; name: string };

    this.updateTimesOfDay(this.timesOfDay);
  }

  checkExceedMaxLength(): boolean {
    if (this.errorsMaxLength) {
      ResponseHandlerModule.showNotify({ message: 'Превышено допустимое количество символов', type: 'fail' });
    }

    return this.errorsMaxLength;
  }

  async mounted(): Promise<void> {
    SettingsModule.initTabsItems();

    await this.initFilterSystem();
    await this.initFilterTimesOfDay();
    await NotificationsModule.getMarkers();
    await this.updateSystem(this.system);

    this.isLoader = true;
  }
}
