/**
 * Notifications entity store.
 *
 */

import { Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import store from '@/store';

import { Marker, NotificationParameters } from '@/interfaces/notifications.interface';

import {
  getNotificationsMarkers,
  getNotificationsRemind,
  updateNotificationsRemind,
  getNotificationsInfo,
  updateNotificationsInfo,
  getNotificationsFirstShift,
  updateNotificationsFirstShift,
  getNotificationsFirstShiftDone,
  updateNotificationsFirstShiftDone,
  getNotificationsCancelShift,
  updateNotificationsCancelShift,
  getNotificationsReactivation,
  updateNotificationsReactivation,
  getNotificationsCheckin,
  updateNotificationsCheckin,
  getNotificationsNewMarket,
  updateNotificationsNewMarket,
  getNotificationsAutoAssigned,
  updateNotificationsAutoAssigned,
} from '@/api/notifications';

import ResponseHandlerModule from '@/store/modules/responseHandler';
import EntityBaseModule from '@/store/entity';
import { strings } from '@/lib/stringConst';
import { EXCEED_LENGTH_MESSAGE } from '@/lib/util/consts';

export const MODULE_NAME = 'notifications';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class NotificationsEntityModule extends EntityBaseModule {
  markers: Array<Marker> = [];
  warning = false;

  constructor(module: NotificationsEntityModule) {
    super(module);
  }

  @Mutation
  SET_MARKERS(value: Array<Marker>) {
    this.markers = value;
  }

  @Mutation
  SET_WARNING(value: boolean) {
    this.warning = value;
  }

  @Action({ rawError: true })
  async getRemindList() {
    try {
      return await getNotificationsRemind();
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async getInfoList() {
    try {
      return await getNotificationsInfo();
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async getFirstShiftList() {
    try {
      return await getNotificationsFirstShift();
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async getFirstShiftDoneList() {
    try {
      return await getNotificationsFirstShiftDone();
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async getCancelShiftList() {
    try {
      return await getNotificationsCancelShift();
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async getReactivationList() {
    try {
      return await getNotificationsReactivation();
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async getCheckInCheckOutList() {
    try {
      return await getNotificationsCheckin();
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async getNewMarketList() {
    try {
      return await getNotificationsNewMarket();
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async getAutoAssignedList() {
    try {
      return await getNotificationsAutoAssigned();
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async getMarkers() {
    try {
      const result = await getNotificationsMarkers();

      this.setMarkers(result);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  setMarkers(markers: Array<Marker>) {
    this.context.commit('SET_MARKERS', markers);
  }

  @Action({ rawError: true })
  async save(params: { model: string; data: NotificationParameters }) {
    const data = params.data;

    try {
      let result = {
        status: '',
        message: '',
      };

      switch (params.model) {
        case 'remindModel': {
          result = await updateNotificationsRemind(data);
          break;
        }
        case 'infoModel': {
          result = await updateNotificationsInfo(data);
          break;
        }
        case 'firstShiftModel': {
          result = await updateNotificationsFirstShift(data);
          break;
        }
        case 'firstShiftDoneModel': {
          result = await updateNotificationsFirstShiftDone(data);
          break;
        }
        case 'cancelShiftModel': {
          result = await updateNotificationsCancelShift(data);
          break;
        }
        case 'reactivationModel': {
          result = await updateNotificationsReactivation(data);
          break;
        }
        case 'checkInCheckOutModel': {
          result = await updateNotificationsCheckin(data);
          break;
        }
        case 'newMarketModel': {
          result = await updateNotificationsNewMarket(data);
          break;
        }
        case 'autoAssignedModel': {
          result = await updateNotificationsAutoAssigned(data);
          break;
        }
      }

      if (result.status === 'ok') {
        let message = 'Изменения сохранены';
        if (this.warning) {
          message += `: ${EXCEED_LENGTH_MESSAGE}`;
        }

        ResponseHandlerModule.showNotify({ message, type: 'ok' });

        return;
      }

      ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data, type: 'fail' });
    }
  }
}

export default getModule(NotificationsEntityModule);
