
import { Component } from '@/lib/decorator';

import FormSimpleSwitch from '@/components/form/Switch/SimpleSwitch.vue';
import IconFont from '@/components/icons/IconFont.vue';
import { GuiTextField } from '@library/gigant_ui';

import AbstractNotification from './AbstractNotification';

@Component({
  emits: ['errorsMaxLength', 'checkinModel'],
  components: {
    FormSimpleSwitch,
    GuiTextField,
    IconFont,
  },
})
export default class NotificationsCheckInCheckOut extends AbstractNotification {
  readonly modelName = 'checkinModel';
}
